import _template from "../../app/library/_template";
import $ from "jquery";

import "./partial/CompanyServiceListPartial";

export default class CompanyLocationSelectUi {

    template = _template('#app_ui_company_event_list_location');
    path = {
        base_company_event_url: null,
        base_company_page_url: null,
        base_company_book_url: null,
    };
    filterData = null;

    constructor(data) {
        this.path = data.path;
        this.filterData = Object.assign({}, data.filterData);
        this.filterData.page = 1;
        this.filterData.maxResults = data.maxResults;

        $(document).on('click', data.el, (event) => {
            let companyEl = $(event.target).closest('.company')[0];

            this.filterData.locationId = $(event.target).data('location-id');
            this.filterData.companyLogin = $(event.target).data('company-login');

            $(companyEl).find('.company__location-address-chosen').html($(event.target).html());
            $(companyEl).find('.toggle').click();
            this.render(companyEl);
        });
    }

    render(companyEl) {
        $.getJSON(this.path.base_company_event_url, this.filterData, (result) => {
            if (result) {
                Object.assign(result, this.path);
                $(companyEl)
                    .find('.service__list')
                    .html(this.template(result));
            }
        });
    }
}