import Factory from "../app/library/map/Factory";

export default class CompanyShowOnMap {

    mapContainer = $('.app-map-container');
    modal = $('.app-map-modal');
    address = $('.app-map-popup-address');

    map = null;

    constructor() {
        this.initEvents();
    }

    initEvents() {
        $(document).on('click', '.app-location', (event) => {
            this.modal.one('shown.bs.modal', () => {
                let location = $(event.target).data('location');
                let address = $(event.target).data('address');

                if (!this.map) {
                    this.initMap(location);
                }
                this.map.clearMarkers();
                this.map.addMarker(location.lat, location.lng, null, null);
                this.map.flyTo(location.lng, location.lat);

                console.log(this.address);

                this.address.text(address);
            });
        });
    }

    initMap(location) {
        this.map = Factory.getMap();
        this.map.attachMap(this.mapContainer, {center: location});
    }

}