import {Model} from "backbone";

const CompanyLocationModel = Model.extend({

    defaults: {
        id: null,
        name: null,
        address: null,
        company_login: null
    }

});

export default CompanyLocationModel;