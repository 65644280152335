import SearchFilterModuleView from "./SearchFilterModuleView";
import _template from "../../../app/library/_template";
import _ from "underscore";

const MapFilterModuleView = SearchFilterModuleView.extend({

    template: _template('#app_filter_map_module_template'),

    ui: {
        'submit': '.app-submit, .app-apply-filter',
        'reset': '.app-reset',
        'form': '.app-form',
        'dropdownOpen': '.app-more',
        'dropdown': '.app-dropdown',
        'dropdownClose': '.app-close-dropdown',
        'filtersList': '.app-list',
        'filtersMobileOpen': '.app-toggle-list',
        'filtersMobileClose': '.app-close-list'
    },
    events: {
        'click @ui.submit': 'triggerSubmit',
        'click @ui.reset': 'reset',
        'click @ui.dropdownOpen': 'onShowFilters',
        'click @ui.dropdownClose': 'onHideFilters',
        'click @ui.filtersMobileOpen': 'onShowFiltersMobile',
        'click @ui.filtersMobileClose': 'onHideFiltersMobile'
    },

    reset: function () {
        this.ui.form.find(':input').val(null);
        this.ui.form.find(':checkbox').prop('checked', false);

        this.triggerSubmit();
    },

    triggerSubmit: function () {
        this.triggerMethod('form:submit', this.getData());

        $('.collapse').collapse('hide');

        return false;
    },

    getData: function () {
        let serialized = this.ui.form.serializeArray();
        let data = {};
        for(let s in serialized){
            if (!serialized.hasOwnProperty(s)) {
                continue;
            }

            let name = serialized[s]['name'];
            let val = serialized[s]['value'];

            if (name.indexOf('[]') !== -1) {
                name = name.replace('[]', '');
                if (!data[name]) {
                    data[name] = [];
                }

                data[name].push(val);
            } else {
                data[name] = val;
            }
        }

        return this.filterData(data);
    },

    filterData: function (data) {
        let result = {};
        _.each(data, (v, k) => {
            if (_.isObject(v)) {
                v = this.filterData(v);
            }
            if (v !== '' && v !== null) {
                result[k] = v;
            }
        });
        return result;
    },

    onShowFilters () {
        const $pageOverlay = $('#dr_overlay');

        this.ui.dropdownOpen.toggleClass('active');
        this.ui.dropdown.toggleClass('show');
        $pageOverlay.toggleClass('show');
    },

    onHideFilters () {
        const $pageOverlay = $('#dr_overlay');

        this.ui.dropdownOpen.toggleClass('active');
        this.ui.dropdown.toggleClass('show');
        $pageOverlay.toggleClass('show');
    },

    onShowFiltersMobile () {
        this.ui.filtersList.toggleClass('active');
        console.log('test');
    },

    onHideFiltersMobile () {
        this.ui.filtersList.toggleClass('active');
    }

});

export default MapFilterModuleView;